<!--运用标志示范工程-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="content-div">
      <!--搜索区域-->
      <div class="searchDiv">
        <el-input
          v-model="keyWord"
          placeholder="请输入地标运用促进工程名称搜索"
          prefix-icon="el-icon-search"
          clearable
          @clear="handleEmpty"
          @keyup.enter.native="searchEnter"></el-input>
        <el-button class="moreBtn" @click="searchEnter">搜索</el-button>
      </div>
      <!--查询条件-->
      <div class="giLabelDiv">
        <div class="label-list-left">
          <div class="label-left-title">地标运用促进工程等级：</div>
        </div>
        <div class="label-list-center">
          <ul class="labelClass">
            <li class="cursorPointer">
              <span :class="labelIsAll == true ? 'labelAll' : 'labelNotAll'" @click="clickLabelTab(item, index)">全部</span>
            </li>
            <li v-for="(item, index) in labelList" :key="index" :class="labelActiveIndex === index ? 'active' : 'unActive'" @click="clickLabelTab(item, index)" class="cursorPointer">
              <span class="querySpanClass">{{ item.label }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--总条数-->
      <div class="totalDiv">
        共 <div class="totalNum">{{tableTotal}}</div>个结果
      </div>
      <!--产品列表-->
      <div class="listContain">
        <div class="infoDiv" v-for="(item, index) in dataList" :key="index">
          <img :src="item.img" v-if="item.img" alt="" />
          <div class="textDiv">
            <!--促进工程名称-->
            <div class="infoTitle">{{ item.giInfoName }}</div>
            <!--单位-->
            <div class="address">{{item.applyOrg}}</div>
            <!--产地范围-->
            <div class="address">{{item.region}}</div>
          </div>
        </div>
      </div>
      <!--分页-->
      <div class="page">
        <el-pagination
          :page-sizes="[12, 20, 50, 100]"
          :page-size="pageSize"
          :current-page.sync="page"
          @current-change="pageChange"
          @size-change="handleSizeChange"
          @prev-click="prevClick"
          @next-click="nextClick"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal">
        </el-pagination>
      </div>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: "index",
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
          path: ''
        },
        {
          name: '运用标志示范工程',
          path: '/giBrand/demonstration'
        },
      ],
      page: 1,
      tableTotal: 0,
      pageSize: 12,
      keyWord: '', //搜索区域：地标名称
      labelIsAll: true,
      labelList: [],//字典值
      dataList: [],
      grade: '', //示范区等级
      labelActiveIndex: '',

    }
  },
  mounted() {
    this.getGiTypeDict()
    this.getList()
  },
  methods: {
    //查询条件字典值
    getGiTypeDict() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/dicFront/selectListByTypeName" + "?typeName=apply_level",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.labelList = res.data.data

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //查询列表
    getList() {
      let params ={
        size: this.pageSize,
        current: this.page,
        giInfoName: this.keyWord,
        grade: this.grade,
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/demon/promotionList",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.dataList = res.data.data.records
          for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].img = this.dataList[i].picUrl
          }
          this.tableTotal = res.data.data.total


        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })

    },

    //查询条件点击
    clickLabelTab(item, index) {
      this.page = 1
      this.pageSize = 12
      if (item == undefined) {
        this.labelActiveIndex = ""
        this.labelIsAll = true
        this.grade = ""
        this.getList()
      } else {
        this.labelActiveIndex = index
        this.labelIsAll = false //全部按钮不显示蓝色
        this.grade = item.value
        this.getList()
      }
    },

    //搜索
    searchEnter() {
      this.getList()
    },

    //清空搜索内容
    handleEmpty() {
      this.getList()
    },

    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .main-div {
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .content-div {
    margin: auto;
    padding: 32px 20px 12px 20px;
    width: 1160px;
    background: #FFFFFF;
    .searchDiv {
      display: flex;
      margin-bottom: 10px;
      margin-left: 15%;
      .moreBtn {
        position: relative;
        left: -20%;
        width: 100px;
        height: 52px;
        background: #186CF5;
        border-radius: 48px;
      }
    }
    .giLabelDiv {
      display: flex;
      margin: 40px 0px 20px 0px;
      .label-list-left {
        width: 155px;
        .label-left-title {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #888888;
          line-height: 21px;
        }
      }
      .label-list-center {
        .labelClass {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin: 0;
          .labelAll {
            color: #409eff;
            margin-right: 40px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
          }
          .labelNotAll {
            color: #888888;
            margin-right: 40px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
          }
        }
      }
    }
    .active {
      margin-bottom: 10px;
      .querySpanClass {
        margin-right: 43px;
        margin-bottom: 8px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #409eff;
      }
    }
    .unActive {
      margin-bottom: 10px;
      .querySpanClass {
        margin-right: 43px;
        margin-bottom: 8px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
      }
    }
    .totalDiv {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
      .totalNum {
        color: #186CF5;
      }
    }
    .listContain {
      min-height: 287px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .infoDiv {
        width: 270px;
        margin: 32px 9px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #EEF2F9;
        text-align: center;
      }
      .infoDiv:hover {
        //background-color: #f0f7fd;
        border: 1px solid #dde5ef;
      }
      .infoDiv > img {
        width: 270px;
        height: 176px;
        border-radius: 5px;
      }
      .textDiv {
        margin: 10px;
        .infoTitle {
          text-align: left;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          line-height: 27px;
          margin: 18px 0px;
        }
        .introduction {
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .address {
          display: flex;
          align-items: center;
          text-align: left;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #7E91BE;
          line-height: 18px;
          margin: 10px 0px;
        }
      }
    }
    .page {
      text-align: center;
    }

  }

  ::v-deep .el-input--prefix .el-input__inner {
    width: 784px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px 0px rgba(20,110,233,0.12);
    border-radius: 48px;
    border: 1px solid #E3EBF9;
  }

  ::v-deep.el-input .el-input__suffix  {
    .el-input__suffix-inner {
      .el-icon-circle-close.el-input__clear {
        margin-right: 200px;
      }
    }
  }
</style>
